<template>
  <div id="app">
    <el-container>
      <el-header v-show="show_menu" v-bind:class="{header_padding:!isMobile}">
        <md-tabs md-sync-route md-alignment="centered" class="md-elevation-3">
          <md-tab md-label="" to="/task" md-icon="home"></md-tab>
          <md-tab md-label="" to="/account" md-icon="group"></md-tab>
          <md-tab md-label="" to="/log" md-icon="description"></md-tab>
          <md-tab md-label="" to="/history" md-icon="restore"></md-tab>
          <md-tab md-label="" to="/config" md-icon="info"></md-tab>
        </md-tabs>
      </el-header>
      <el-main>
        <router-view/>
      </el-main>
      <el-footer>
        <a target="_blank"
           v-if="show_beian"
           class="beian_style"
           href="https://beian.miit.gov.cn/?spm=a2c4g.11174386.n2.3.21c361dbUY0BG9">
          <img src="../public/gongan.png" style="display: block;width: fit-content;height: fit-content"/>
          <p style="line-height:10px;color:#939393;margin-left: 5px">京ICP备2025107978号-1</p>
        </a>
      </el-footer>
    </el-container>
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {
  },
  computed: {
    show_menu () {
      return this.$route.path !== '/'
    },
    show_beian () {
      return this.$route.path === '/'
    }
  },
  data () {
    return {
      isMobile: true
    }
  },
  methods: {
  },
  mounted () {
    if (this.$route.path === '/' && localStorage.getItem('token') != null) {
      this.$router.push('/task')
    }
  },
  created () {
    this.isMobile = this.$device.mobile
  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0 auto;
  padding: 0;
  max-width: 550px;
}

body{
  background-color: #f5f5f5 !important;
}

.el-header{
  /*background-color: rebeccapurple;*/
  color: #333;
  text-align: center;
  line-height: 60px;
  margin: 0;
  padding: 0;
}

.el-footer{
  /*background-color: rebeccapurple;*/
  color: #333;
  text-align: center;
  line-height: 60px;
  margin: 0;
  padding: 0;
}

.header_padding{
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.el-main {
  /*background-color: #1978d3;*/
  color: #333;
  text-align: center;
  margin: 0 !important;
  /*padding: 0;*/
  padding-left: 16px !important;
  padding-right: 16px !important;
  min-height: 200px !important;
  max-width: 700px !important;
}

body > .el-container {
  margin: 0;
  padding: 0;
}

/* 更改选中文字的背景颜色 */
::selection {
  background-color: #bbd6fb !important;
  /*color: #fff; !* 更改选中文字的颜色，如果需要 *!*/
}

.beian_style{
  width:fit-content;
  height: fit-content;
  padding: 0;
  margin:0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-decoration: none !important;
  /*background: grey;*/
  margin-top: 40px;
}
</style>
