<template>
<div>
  <md-card class="md-elevation-1"
           v-if="top3_latest_news.length>0"
           style="margin-bottom: 16px;padding-bottom: 0;">
    <md-card-header class="notice">
      <div v-for="(item,idx) in top3_latest_news" :key="idx" class="news_bar">
        <i class="mdui-icon material-icons notification_icon">notifications_active</i>
        <div class="md-subhead" style="margin-left: 8px">
          <a target="_blank"
             style="text-decoration: none !important;color: inherit"
             :href="getNewsUrl(item)">
            {{item.title}}——{{item.gmtCreate}}
          </a>
        </div>
      </div>
    </md-card-header>
  </md-card>

  <md-card class="md-elevation-1">
    <md-card-header>
      <div class="md-title">订单信息</div>
    </md-card-header>

    <md-card-content>
      <el-form  ref="form"
                :model="form"
                :rules="rules"
                inline="false"
                label-width="120px">
        <el-form-item label="目标场馆" prop="venue">
          <el-radio-group v-model="form.venue" v-on:change="clearTargetTime">
            <el-radio label="邱德拔"></el-radio>
            <el-radio label="五四"></el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="目标日期" prop="target_date">
          <el-date-picker type="date"
                          placeholder="请选择日期"
                          v-model="form.target_date"
                          value-format="yyyy-MM-dd"
                          style="width: 100%;"></el-date-picker>
        </el-form-item>
        <el-form-item label="目标时间" prop="target_time">
          <el-select v-model="form.target_time"
                     multiple
                     :multiple-limit="2"
                     style="width: 100%"
                     placeholder="请选择时间">
            <div v-show="form.venue==='邱德拔'">
              <el-option
                v-for="item in Array.from({length: 15}, (_, i) => i + 8)"
                :label=parseTime(item)
                :key="item"
                :value="item">
              </el-option>
            </div>
            <div v-show="form.venue==='五四'">
              <el-option
                v-for="item in Array.from({length: 16}, (_, i) => i + 7)"
                :label=parseTime(item)
                :key="item"
                :value="item">
              </el-option>
            </div>
          </el-select>
        </el-form-item>
        <el-form-item label="下单账号" prop="account">
          <el-select v-model="form.account"
                     style="width: 100%"
                     placeholder="请选择下单账号">
            <el-option
              v-for="item in accounts_info"
              :label="item.name+getDisplayBalance(item.student_id)"
              :key="item.id"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="目标场号" prop="court_fav">
          <el-input v-model="form.court_fav" placeholder="用英文逗号隔开，如7,9,11"></el-input>
        </el-form-item>
        <el-form-item label="自动付款">
          <el-switch v-model="form.auto_pay"></el-switch>
        </el-form-item>
        <el-form-item label="付款方式">
          <el-radio-group v-model="form.payment_method" :disabled="!form.auto_pay">
            <el-radio :label="1">场馆余额</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="微信提醒">
          <el-switch v-model="form.wechat_alert"></el-switch>
        </el-form-item>
        <el-form-item label="提醒账号">
          <el-select v-model="form.wechat_account"
                     :disabled="!form.wechat_alert"
                     style="width: 100%"
                     v-on:change="setWechatName"
                     placeholder="请选择预约结果提醒账号">
            <el-option
              v-for="item in wechat_info"
              :label="item.name"
              :key="item.id"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </md-card-content>

    <md-card-actions>
      <md-button @click="goBack">取消</md-button>
      <md-button class="md-raised md-primary" @click="submitForm('form')">立即创建</md-button>
    </md-card-actions>
  </md-card>

</div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'AddTask',
  computed: {
  },
  data () {
    return {
      form: {
        target_time: [],
        venue: '邱德拔',
        target_date: '',
        target_court: '',
        court_fav: '',
        account: '',
        same_court: false,
        wechat_alert: false,
        wechat_account: null,
        auto_pay: true,
        payment_method: 1,
        wechat_name: null,
      },
      rules: {
        venue: [
          { required: true, message: '请选择场馆', trigger: 'change' }
        ],
        target_date: [
          { required: true, message: '请选择目标日期', trigger: 'blur' }
        ],
        target_time: [
          { required: true, message: '请输入目标时间', trigger: 'blur' }
        ],
        account: [
          { required: true, message: '请选择下单账号', trigger: 'change' }
        ],
        court_fav: [
          { validator: this.checkCourtFav, trigger: 'change' }
        ]
      },
      accounts_info: [],
      wechat_info: [],
      top3_latest_news: [],
      balance: {} // 余额信息，包括余额和更新时间
    }
  },
  methods: {
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const formData = {}
          formData.token = localStorage.getItem('token')

          // 订单基本信息
          formData.target_date = this.form.target_date
          if (this.form.venue === '邱德拔') {
            formData.venue_type = 'qdb'
          } else {
            formData.venue_type = '54'
          }
          formData.target_time = this.stringifyTargetTime()
          formData.court_fav = this.form.court_fav
          formData.account_id = this.form.account
          formData.account_name = this.getAccountName(this.form.account)
          formData.target_court = this.form.target_court !== '' ? this.form.target_court : -1

          // 自动付款信息
          formData.auto_pay = this.form.auto_pay
          formData.payment_method = this.form.payment_method

          // 微信提醒信息
          formData.wechat_alert = this.form.wechat_alert
          formData.wechat_account = this.form.wechat_account
          formData.wechat_name = this.form.wechat_name

          axios.post('add_task', formData).then(res => {
            if (res.data.code === 200) {
              this.$message({
                message: '创建成功！',
                type: 'success'
              })
              this.$router.push('/task')
            } else {
              this.$message({
                message: '创建失败！' + res.data.message,
                type: 'error'
              })
            }
          }).catch(err => {
            if (err.response.status === 401) {
              // 身份认证失败，有可能是7天免登录过期了
              localStorage.removeItem('token')
              this.$router.push('/')
              this.$message({
                message: '身份认证过期（失败）！',
                type: 'warning'
              })
            }
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    getDisplayBalance(student_id){
      if(this.balance===null || this.balance==={} || this.balance===undefined){
        return ""
      }
      else if(!(student_id in this.balance)){
        return ""
      }
      else if(this.balance[student_id]===null){
        return ""
      }else{
        return " ￥"+this.balance[student_id]['balance'].toString()
      }
    },
    getAccountsInfo () {
      axios.post('get_all_epe_accounts', { token: localStorage.getItem('token') }).then(res => {
        this.accounts_info = res.data.data

        // 生成学号list获取余额信息存到balance变量
        const accounts = [];
        for(let i=0; i<this.accounts_info.length; i++){
          accounts.push(this.accounts_info[i].student_id)
        }
        this.getBalanceInfo(accounts)

      }).catch(err => {
        if (err.response.status === 401) {
          // 身份认证失败，有可能是7天免登录过期了
          localStorage.removeItem('token')
          this.$router.push('/')
          this.$message({
            message: '身份认证过期（失败）！',
            type: 'warning'
          })
        }
      })
    },
    getBalanceInfo(accounts){
      // 获取账号余额信息
      axios.post('get_all_balance', { token: localStorage.getItem('token'), accounts: accounts }).then(res => {
        if(res.data.code===200){
          this.balance = res.data.data
          this.$forceUpdate()
        }else{
          this.$message({
            message: '更新余额失败！' + res.data.message,
            type: 'warning'
          })
        }
      }).catch(err => {
        this.$message({
          message: err,
          type: 'error'
        })
      })
    },
    parseTime (start) {
      if (start === 7) {
        return '6:50-7:50'
      } else {
        return start + ':00-' + (start + 1) + ':00'
      }
    },
    goBack () {
      this.$router.push('/task')
    },
    getTodayDateString () {
      // 创建当前日期对象
      const today = new Date()

      // 增加三天
      const threeDaysLater = new Date(today.getTime() + (3 * 24 * 60 * 60 * 1000))

      // 格式化输出日期
      const year = threeDaysLater.getFullYear()
      const month = String(threeDaysLater.getMonth() + 1).padStart(2, '0')
      const date = String(threeDaysLater.getDate()).padStart(2, '0')
      return `${year}-${month}-${date}`
    },
    getAccountName (id) {
      for (let i = 0; i < this.accounts_info.length; i++) {
        if (this.accounts_info[i].id === id) {
          return this.accounts_info[i].name
        }
      }
    },
    stringifyTargetTime () {
      if (this.form.target_time.length === 1) {
        return '[' + this.form.target_time[0].toString() + ']'
      } else if (this.form.target_time.length === 2) {
        return '[' + this.form.target_time[0].toString() + ',' + this.form.target_time[1].toString() + ']'
      }
    },
    clearTargetTime () {
      this.form.target_time = []
    },
    checkCourtFav (rule, value, callback) {
      if (value.trim() === '') {
        callback()
      } else if (!this.isValidCourtFav(value)) {
        callback(new Error('只能包含数字和英文逗号'))
      } else {
        callback()
      }
    },
    isValidCourtFav (text) {
      // 检查输入是不是只包含数字和英文逗号
      const regex = /^[0-9,]+$/
      return regex.test(text)
    },
    getTop3LatestNews () {
      axios.post('get_top3_latest_news', { token: localStorage.getItem('token') }).then(res => {
        this.top3_latest_news = res.data.data
      }).catch(err => {
        console.log(err)
      })
    },
    getNewsUrl (item) {
      let url = ''
      if (item && item.content !== null) {
        url = 'https://epe.pku.edu.cn/venue/pku/homeArticle?id=' + item.id + '&type=2'
      } else {
        url = 'https://epe.pku.edu.cn/venue/pku/articles'
      }
      return url
    },
    getWechatAccountsInfo () {
      // 获取微信推送账号信息
      axios.post('get_all_wechat_accounts', { token: localStorage.getItem('token') }).then(res => {
        this.wechat_info = res.data.data
      }).catch(err => {
        console.log(err)
      })
    },
    setWechatName () {
      for (let i = 0; i < this.wechat_info.length; i++) {
        if (this.wechat_info[i].id === this.form.wechat_account) {
          this.form.wechat_name = this.wechat_info[i].name
          break
        }
      }
    }
  },
  mounted () {
    this.getAccountsInfo()
    this.form.target_date = this.getTodayDateString()
    this.getTop3LatestNews()
    this.getWechatAccountsInfo()
  }
}
</script>

<style scoped>
.el-form-item {
  display: flex;
  flex-direction: row;
}

::v-deep .el-form-item__content{
  margin-left: 20px;
  /*background: green;*/
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.md-card {
  display: inline-block;
  vertical-align: top;
  text-align: left;
  width: 100%;
}

.notice{
  display: flex;
  flex-direction: column;
}

.notice:last-child {
  margin-bottom: 0px;
}

.news_bar{
  display: flex;
  flex-direction: row;
  margin-bottom: 8px
}

.notification_icon{
  color: #757575;
  font-size: 20px;
}
</style>
